import { Stack, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import React from "react";
import { MEASURE_TYPES } from "../../utils/constants";
import StelDataGrid from "../StelDataGrid";

function AllowedModelsTable(props) {
  const { rows, loading } = props;
  const columns = [
    {
      field: "id",
      flex: 0.5,
      headerName: "Make/Model",
      valueGetter: (params) => `${params.row.make} ${params.row.name}`,
    },
    {
      field: "type",
      flex: 0.5,
      headerName: "Type",
      valueGetter: (params) => MEASURE_TYPES[params.row.type].label,
    },
  ];

  return (
    <Stack>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          mb: "10px",
        }}
      >
        Allowed Models
      </Typography>
      <StelDataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        hideFooter
      />
    </Stack>
  );
}

AllowedModelsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AllowedModelsTable;
