import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";

function EditToolbar(props) {
  const { setRowImports, setRowModesModel } = props;

  const handleClick = () => {
    const id = Math.random();
    setRowImports((oldRows) => [
      ...oldRows,
      {
        id,
        bluetooth_code: "",
        box_data_matrix: "",
        data_matrix: "",
        serial_number: "",
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowImports: PropTypes.func.isRequired,
  setRowModesModel: PropTypes.func.isRequired,
};

export default function ModalImportTable(props) {
  const {
    open,
    onClose,
    rows,
    columnsImports,
    setRowImports,
    handleImportDexcom,
    organizations,
    organization,
    setOrganization,
  } = props;
  const [rowModesModel, setRowModesModel] = useState({});

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowImports(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleDeleteClick = useCallback(
    (id) => {
      setRowImports(rows.filter((row) => row.id !== id));
    },
    [rows, setRowImports]
  );

  const handleRowModesModelChange = useCallback((model) => {
    setRowModesModel(model);
  }, []);

  const getActions = useCallback(
    ({ id }) => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={() => handleDeleteClick(id)}
        color="inherit"
      />,
    ],
    [handleDeleteClick]
  );

  const columns = [
    ...columnsImports,
    {
      field: "actions",
      type: "actions",
      width: 100,
      cellClassName: "actions",
      getActions,
    },
  ];

  useEffect(() => {
    if (isEmpty(rowModesModel)) return;

    const [rowId] = Object.keys(rowModesModel);
    const modifiedRowData = rowModesModel[rowId];

    const newData = Object.fromEntries(
      Object.entries(modifiedRowData).map(([key, { value }]) => [key, value])
    );

    setRowImports(
      rows.map((row) =>
        Number(row.id) === Number(rowId) ? { ...newData, id: row.id } : row
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rowModesModel)]);

  return (
    <StelModal open={open} onClose={onClose} title="Import Devices">
      <FormControl size="small" fullWidth sx={{ marginBottom: "20px" }}>
        <InputLabel>Organization *</InputLabel>
        <Select
          size="small"
          value={organization}
          label="Organization *"
          onChange={(e) => setOrganization(e.target.value)}
        >
          {organizations.map((org) => (
            <MenuItem key={org.id} value={org.alias}>
              {org.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <StelDataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        onEditRowsModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRowImports, setRowModesModel },
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          disabled={!rows.length || !organization}
          onClick={handleImportDexcom}
          sx={{ marginTop: "20px" }}
        >
          Import Device
        </Button>
      </Box>
    </StelModal>
  );
}

ModalImportTable.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf.isRequired,
  columnsImports: PropTypes.arrayOf.isRequired,
  setRowImports: PropTypes.func.isRequired,
  handleImportDexcom: PropTypes.func,
  organizations: PropTypes.arrayOf,
  organization: PropTypes.string.isRequired,
  setOrganization: PropTypes.func,
};

ModalImportTable.defaultProps = {
  handleImportDexcom: () => {},
  organizations: [],
  setOrganization: () => {},
};
